.app__works {
  padding: 20px 0;
  flex: 1;
  width: 100%;
  flex-direction: column;
}
.app__work-filter {
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0;

  .app__work-filter-item {
    // padding: 0.5rem 1rem;
    // border-radius: 0.5rem;
    border-radius: 10px;
    padding: 4px 8px;
    background-color: #fff;
    color: #000;
    // font-weight: 800;
    font-weight: 450;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--primary-color2);
      color: #fff;
    }
    @media screen and (max-width: 900px) {
      // border-radius: 13px;
      font-size: 15px;
    }
  }
  .item-active {
    background-color: var(--primary-color2);
    color: #fff;
  }
}
.app__work-wrapper {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  cursor: pointer;
  margin: 10px 20px;
  transition: all 0.5s ease;

  &:hover {
    transform: translateY(-8px);
  }
  &:hover .app__work-title a {
    color: var(--primary-color2);
  }
}
.app__work-title {
  cursor: pointer;
  z-index: 5;
  transform-origin: top left;
  left: -3px;
  top: 0px;
  transform: rotate(90deg);
  position: absolute;

  display: flex;
  // height: 100%;
  width: 100%;
  // border: 1px solid red;
  font-size: 15px;
  color: var(--primary-color2);
  // border: 1px solid red;
  white-space: nowrap;

  a {
    text-decoration: none;
    color: #999;
    text-transform: uppercase;
  }
}
.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    position: relative;
    // border: 1px solid red;
    transition: all 0.3s ease;
    width: 250px;
    min-height: 320px;
    transition: all 0.3s ease;
    display: flex;
    // border: 1px solid black;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: ;
    // margin: 10px;
    border-radius: 0.5rem;
    color: #000;
    background-color: #fff;
    // overflow: hidden;
    // padding: 1rem;

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__work-img {
  width: 100%;
  height: 150px;
  position: relative;

  img {
    object-fit: cover;
    // border-radius: 0.5rem;
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transform: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    margin: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}
.app__work-tech {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  span {
    // border: 1px solid #999;
    color: var(--primary-color2);
    margin: 0 3px;
    padding: 3px;
    border-radius: 5px;
    font-size: 12px;
    background: rgba(238, 238, 238, 0.8);
  }
}

.app__work-content {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    // margin-top: 1rem;
    line-height: 1.5;
  }
}
.app__work-tag {
  // border: 1px solid red;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  text-transform: uppercase;
  font-size: 12px;
  // padding: 0.5rem 1rem;
  // border-radius: 10px;
  background: rgba(238, 238, 238, 0.8);

  // background-color: var(--primary-color2);
  // color: #fff;
  // top: -25px;
}

.app__work-desc {
  // border: 1px solid red;
}

.app__work-actions {
  // border: 1px solid #000;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  a {
    font-size: 20px;
    text-decoration: none;
    color: var(--primary-color2);
  }
}
