.contact {
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  // padding: 20px 40px;

  @media (max-width: 850px) {
    padding: 0;
  }
  p {
    padding: 0 20px;
    margin: 15px 0;
  }
}
.contact-container {
  box-shadow: 7px 11px -10px rgba(0, 0, 0, 0.33);
  width: 70%;
  // max-height:400px ;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid var(--primary-color2);
  margin: 0 auto;
  overflow: hidden;
  border-radius: 25px;
  background: var(--color-gray);
  margin: 20px auto;
  & > div {
    // width: 50%;
    flex: 0.5;
  }
  @media (max-width: 850px) {
    & {
      width: 90%;
      border: none;
      justify-content: center;
      flex-flow: column-reverse nowrap;
      // width: 100%;
      margin: 15px auto;
    }
    & > div {
      min-width: 100%;
      flex: 1;
    }
  }
}
.isound {
  bottom: 0;
  right: -10%;
}
.iboxball {
  bottom: 0;
  right: 0;
}
.app__contact-card {
  position: relative;
  max-width: 50%;
  height: 100%;
  display: flex;
}
.contact__card-info {
  z-index: 2;

  // border: 1px solid red;
  // background: #fff;
  padding: 0 2rem;
  margin: 20px 0;
}
.contact__card-link {
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  transition: all 0.3s ease;
  text-decoration: none;
  margin: 20px 0;

  &,
  &:focus,
  &:visited {
    color: var(--color-gray);
  }

  &:hover {
    transform: translateY(-10px);
  }
  .contact__card-iconHolder {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    --c1: #007ced;
    --c2: #008bf5;
    cursor: pointer;
    background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
      var(--x, 0) / 200%;
    color: var(--color-white);
    margin: 0 10px 0 0;
    &:hover {
      --x: 100%;
    }
    svg {
      font-size: 20px;
    }
  }
}

.app__contact-form {
  max-width: 48%;
  //   border: 1px solid black;
  padding: 20px 2rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .form-input {
    box-sizing: border-box;
    background: #f3f3f3;
    border: none;
    display: flex;
    font-size: 0.9rem;
    padding: 0.9rem 1.6rem;
    margin: 0.4rem 0;
    border-radius: 10px;
    transition: all 0.3s ease;
    outline: none;
    min-width: 100%;
  }

  textarea {
    resize: none;
    min-height: 140px;
    min-width: 100%;
    resize: vertical;
  }
}
.form-btn {
  --c1: #007ced;
  --c2: #008bf5;
  border: none;
  font-weight: 500;
  outline: none;
  padding: 0.85rem 2rem;
  // border-radius: 2rem;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s ease;
  cursor: pointer;
  background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
    var(--x, 0) / 200%;
  color: white;
  z-index: 3;

  &:hover {
    --x: 100%;
    transform: translateY(10px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}
.contact-response {
  padding: 10px 20px;
}
.contact-resend {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  svg {
    color: rgb(154, 205, 50);
    font-size: 80px;
    margin: 15px 0;
  }
  button {
    background: rgb(154, 205, 50);
  }
}
