#home {
  position: relative;
  // background: url('../../assets/bgIMG.png');
  // background-size: cover;
  // background-repeat: repeat;
  // background-position: center;
  .app__wrapper {
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 1500px;
  }
  .copyright {
    display: none;
  }
}
.app__header {
  position: relative;
  gap: 1.5rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem 0;

  .app_header-center {
    // border: 1px solid red;
    height: 100%;
    // height: auto !important;
    // max-height: 300px !important;

    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    z-index: 3;
    justify-content: center;
    align-items: center;

    & > div {
      // width: 50%;
      flex: 0.5;
    }
    @media (max-width: 850px) {
      & {
        // border: none;
        flex-flow: column wrap;
        width: 100%;
        margin: 0 auto;
      }
      & > div {
        min-width: 100%;
        flex: 1;
      }
    }
  }
  // max-height: calc(100vh-90rem);
}

.app__header-info {
  // padding: 20px 40px;
  padding: 0 20px;
  flex: 0.65;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  h1 {
    font-size: 3.8rem;
    line-height: 0.8;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.6;
    margin: 2.15rem 0;
  }
}
.header__btn {
  --c1: #007ced;
  --c2: #008bf5;
  border: none;
  font-weight: 500;
  outline: none;
  padding: 0.85rem 2rem;
  border-radius: 2rem;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s ease;
  cursor: pointer;
  background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
    var(--x, 0) / 200%;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  // bouncing button from services.scss
  animation: bouncingbutton 2s alternate infinite;

  &:hover {
    --x: 100%;
    transform: translateY(10px);
  }
}

.app__header-img {
  width: 330px;
  // flex: 1;
  display: flex;
  // height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  // border: 1px solid red;

  img {
    // border: 1px solid black;
    width: 100%;
    max-width: 800px;
    // object-fit: contain;
    z-index: 1;
    // transform: translateY(-60px);
  }



  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}

// .app__header-circles {
//   flex: 0.75;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   align-items: flex-start;
//   height: 100%;
//   margin-left: 1rem;

//   div {
//     width: 100px;
//     height: 100px;
//     border-radius: 50%;
//     background: var(--white-color);
//     box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

//     img {
//       width: 60%;
//       height: 60%;
//     }
//   }
//   div:nth-child(1) {
//     width: 100px;
//     height: 100px;
//   }
//   div:nth-child(2) {
//     width: 150px;
//     height: 150px;
//     margin: 1.75rem;
//   }
//   div:nth-child(3) {
//     width: 70px;
//     height: 70px;
//   }
//   @media screen and(min-width:2000px) {
//     div:nth-child(1) {
//       width: 400px;
//       height: 400px;
//     }
//     div:nth-child(2) {
//       width: 170px;
//       height: 170px;
//       margin: 1.75rem;
//     }
//     div:nth-child(3) {
//       width: 200px;
//       height: 200px;
//     }
//   }
//   @media screen and(max-width:1200px) {
//     width: 100%;
//     flex-flow: row wrap;
//     margin-left: 0;

//     div {
//       margin: 1rem;
//     }
//   }
//   @media screen and(max-width:700px) {
//     flex-wrap: nowrap;
//     div:nth-child(1) {
//       width: 70px;
//       height: 70px;
//     }
//     div:nth-child(2) {
//       width: 70px;
//       height: 70px;
//       margin: 1.75rem;
//     }
//     div:nth-child(3) {
//       width: 70px;
//       height: 70px;
//     }
//   }
// }

// Header OVerlay
.app_header-overlay {
  z-index: 1;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 124, 237, 0.3);
  top: 0;
  left: 0;
  .bgshapes {
    position: absolute;
    opacity: 0.13;
  }

  .namedlogo {
    opacity: 0.06;
    top: -5px;
    left: 15px;
    transform: rotate(180deg);
  }
  .isound {
    right: -10%;
    top: -80%;
  }
  .ibgroup {
    left: 20px;
    top: -10px;
  }
  .iboxball {
    right: 120px;
    top: -410px;
  }
  .ihcircle {
    right: 30px;
    bottom: 20px;
  }
}
// End of head Overlay
