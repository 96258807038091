.app__services {
  padding: 20px 0;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}
.app__profiles {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 33px;
  @media screen and (max-width: 850px) {
    gap: 25px;
    justify-content: center;
    align-items: center;
  }
}

.services-intro {
  box-shadow: 7px 11px 20px -10px rgba(0, 0, 0, 0.33);

  font-size: 2.2em;
  padding: 20px;
  border-radius: 12px;
  --c1: #007ced;
  --c2: #008bf5;
  background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
    var(--x, 0) / 200%;
  color: var(--color-white);
  min-width: 325px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease;

  &:hover {
    --x: 100%;
  }
  button {
    margin: 15px 0;
    color: #007ced;
    // color: var(--primary-color2);
    transition: all 0.3s ease;
    background: var(--color-white);
    align-self: flex-start;
    white-space: nowrap;
    animation: bouncingbutton 2s alternate infinite;
  }
  @keyframes bouncingbutton {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
}
.app__profile-item {
  // overflow: hidden;
  padding: 20px;
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--gray-color);
  min-width: 325px;
  max-width: 325px;
  min-height: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  &:hover {
    cursor: pointer;
    border: none;
  }
  &:hover .serviceholder-overlay {
    --x: 100%;
    width: 100%;
    height: 100%;
  }
  &:hover .services-details {
    p {
      color: var(--color-white);
    }
  }
  &:hover .services-Imgholder {
    transition: all 0.5s ease-in-out;
    --x: 100%;

    // animation: ripple 2s infinite;
    // outline: none;
    border: none;
    // outline: 7px solid var(--color-white);
    // border: 2px solid var(--gray-color);
    // border: none !important;
  }
  .services-Imgholder {
    --c1: #007ced;
    --c2: #008bf5;
    background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
      var(--x, 0) / 200%;
    position: absolute;
    right: -10px;
    top: -25px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 10px;
    outline: 7px solid var(--color-white);
    // border: 2px solid var(--gray-color);
    overflow: hidden;
  }
  img {
    // width: 100%;
    // height: 170px;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    // object-fit: cover;
  }

  @media screen and (max-width: 850px) {
    margin: 1rem 0;
  }
}
.head-text {
  span {
    margin: 0 10px;
  }
}
.serviceholder-overlay {
  transition: all 0.6s ease;
  top: 0;
  left: 0;
  border-radius: 12px;
  position: absolute;
  width: 0px;
  height: 0px;
  z-index: 2;
  transform: rotate(180deg);
  --c1: #007ced;
  --c2: #008bf5;
  background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
    var(--x, 0) / 200%;
}
.services-details,
.services-Imgholder {
  z-index: 3;
}
.services-details {
  // p {
  //   color: var(--color-white);
  // }
}
