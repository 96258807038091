@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
    --font-base: "DM Sans", sans-serif;
    --bg-trans: rgba(255, 255, 255, 0.25);
    --ghost-border: rgba(255, 255, 255, 0.18);
    --primary-color: #edf2f8;
    --primary-color2: #215FF6;
    --secondary-color: #313bac;
    --secondary-color2: #007ced;
    --black-color: #030303;
    --lightGray-color: #e4e4e4;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: #ffffff;
    --color-white: #fff;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    font-size: 15px;
    /* height: 2500px; */
    background-color: var(--primary-color);
    font-family: var(--font-base);
}

::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--color-text);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .4);
}