#FrontendBootcamp{
  display: flex;
  justify-content: center;
  align-items: center;
}


.app__skills {
  // border: 1px solid red;

  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 20px;
}
.app__skills-container {
  // margin-top: 1.3rem;
  width: 80%;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  // border: 1px solid red;
  padding: 0 20px;
  align-items: flex-start;
  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.app__skills-list {
  // border: 1px solid red;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // align-items: flex-start;
  align-items: center;
  // justify-content: center;
  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
.app__skills-item {
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0.8rem;
  transition: all 0.3s ease-in-out;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    --c1: #007ced;
    --c2: #008bf5;
    background: var(--primary-color2);
    // background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
    //   var(--x, 0) / 200%;
    color: white;
    transition: all 0.3s ease;

    img {
      width: 60%;
      height: 60%;
    }
    &:hover {
      box-shadow: 0 0 25px #fef4f5;
      transform: translateX(-10px);
      animation: ripple 2s infinite;
    }
    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }
    @media screen and (max-width: 850) {
      width: 50px;
      height: 50px;
    }
  }
  p {
    font-weight: 400;
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;
    p {
      margin-top: 1rem;
    }
  }
}
.app__skills-exp {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  @media screen and (max-width: 900px) {
    width: 100%;
    // width: max-content;
    margin: 2rem auto 0 auto;
    // border: 1px solid red;
  }
}

.app__skills-exp-item {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  // justify-content: flex-start;
  align-items: flex-start;
  // margin: 1rem 0;
}
.app__skill-year {
  width: 40px;
  display: flex;
  margin-right: 3rem;
  font-weight: 700;
  font-size: 18px;
}
.app__skills-exp-works {
  position: relative;
  width: 100%;
  .skillball {
    display: none;
    position: absolute;
    top: 3px;
    left: -30px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--primary-color2);
    transition: all 0.3s ease;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    align-items: center;

    // &::after {
    //   z-index: 2;

    //   content: '';
    //   position: 'absolute';
    //   // width: 2px;
    //   padding: 2px;
    //   display: block;
    //   outline: none;

    //   // height: 150%;
    //   height: 100%;
    //   background: var(--primary-color2);

    //   // transform: scale(1, 2);
    //   transform: scale(1, 5);
    //   transform-origin: 100% -100%;
    // }

    // &::before {
    //   z-index: 1;

    //   content: '';
    //   position: 'absolute';
    //   // width: 2px;
    //   padding: 2px;
    //   outline: none;
    //   display: block;
    //   // height: 150%;
    //   height: 100%;
    //   background: var(--primary-color2);

    //   // transform: scale(1, 2);
    //   transform: scale(1, 8);
    //   transform-origin: 100% 100%;
    // }
  }
  &:hover .skillball {
    display: flex;
    animation: ripple 2s infinite;
  }

  .app__skills-exp-work {
    // border: 1px solid black;
    color: var(--primary-color2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // margin-bottom: 1rem;
    cursor: pointer;
  }
  h4 {
    font-weight: 500;
  }
  p {
    font-weight: 500;
    color: var(--gray-color);
    // margin-top: 5px;
  }
}

.app__skills-exp
  > .app__skills-exp-item:first-of-type
  > .app__skills-exp-works
  > .skillball {
  animation: ripple 2s infinite;
}
.app__skills-exp
  > .app__skills-exp-item:first-of-type
  > .app__skills-exp-works
  > .skillball::before {
  display: none;
}
.app__skills-exp
  > .app__skills-exp-item:last-of-type
  > .app__skills-exp-works
  > .skillball::after {
  display: none;
}

.app__skills-exp-year {
  // margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    // margin-right: 1rem;
  }
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}
